import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./SchedulePage.scss";
import WorkPrinc from "./WorkPrinc/WorkPrinc";
import SelectDateButton from "./SelectDateButton/SelectDateButton";
import SelectSubjectButton from "./SelectSubjectButton/SelectSubjectButton";
import SelectTime from "./SelectTime/SelectTime";
import { useDispatch, useSelector } from "react-redux";
import { GoogleSpreadsheet } from "google-spreadsheet";
import credentials from "../../../Assets/config";
import Loader from "../Loader/Loader";
import {Helmet} from 'react-helmet'



const d = [];
// var get = {
//   dayOne : [],
//   dayTwo : [],
//   dayThree : [],
// }
const SchedulePage = () => {
  const [date, setDate] = useState([]);
  const [subjectError, setSubjectError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [isTwelve, setIsTwelve] = useState(true);
  const [isOne, setIsOne] = useState(true);
  const [isTwo, setIsTwo] = useState(true);
  const [isThree, setIsThree] = useState(true);
  const [isFour, setIsFour] = useState(true);
  const [isFive, setIsFive] = useState(true);
  const [isSix, setIsSix] = useState(true);
  const [isSeven, setIsSeven] = useState(true);
  const [isEight, setIsEight] = useState(true);
  const [loading, setLoading] = useState(false)
  const [timeCheck, setTimeCheck] = useState(true)
  const history = useHistory();

  const SPREADSHEET_ID = credentials.spreadSheetId;
  const SHEET_ID = credentials.sheetId;
  const CLIENT_EMAIL = credentials.client_email;
  const PRIVATE_KEY = credentials.private_key;
  const SPREADSHEET_ID_DUPLICATE = "1ENR3MySQ2IWiMEKNIKgTS_SEVgitMDg40-C7lCR7QKI"

  const dispatch = useDispatch();
  const essentials = useSelector((state) => state.essential);

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
  const docDuplicate = new GoogleSpreadsheet(SPREADSHEET_ID_DUPLICATE)

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };
  const appendSpreadsheetDuplicate = async (row) => {
    try {
      await docDuplicate.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      await docDuplicate.loadInfo();

      const sheet = docDuplicate.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  useEffect(() => {
    const dateSet = async () => {

      if (!essentials.hasOwnProperty('parentPhone')) {
        history.push('/')
      }
      dateAdder(1);
      dateAdder(2);
      dateAdder(3);
      if (timeCheck) {
        // dateAvailibilty()
        setTimeCheck(false)
      }
      const disableIt = () => {
        setIsTwelve(true);
        setIsOne(true);
        setIsTwo(true);
        setIsThree(true);
        setIsFour(true);
        setIsFive(true);
        setIsSix(true);
        setIsSeven(true);
        setIsEight(true);
        
          if (essentials.timeAvailibility[0] >= 3) {
            setIsTwelve(false);
          }
          if (essentials.timeAvailibility[1] >= 3) {
            setIsOne(false);
          }
          if (essentials.timeAvailibility[2] >= 3) {
            setIsTwo(false);
          }
          if (essentials.timeAvailibility[3] >= 3) {
            setIsThree(false);
          }
          if (essentials.timeAvailibility[4] >= 3) {
            setIsFour(false);
          }
          if (essentials.timeAvailibility[5] >= 3) {
            setIsFive(false);
          }
          if (essentials.timeAvailibility[6] >= 3) {
            setIsSix(false);
          }
          if (essentials.timeAvailibility[7] >= 3) {
            setIsSeven(false);
          }
          if (essentials.timeAvailibility[8] >= 3) {
            setIsEight(false);
          }
        
      };
      if (essentials.timeAvailibility) {
        disableIt();
      }
    };
    dateSet();
  }, [essentials.timeAvailibility]);

  const dateAdder = (inc) => {
    return new Promise((resolve, reject) => {
      var today = new Date();
      today.setDate(today.getDate() + inc);
      var newDate = {
        weekName: today.toString().split(" ")[0],
        month: today.toString().split(" ")[1],
        day: today.toString().split(" ")[2],
        id: inc,
      };
      switch (newDate.weekName) {
        case "Mon":
          newDate.weekName = "MONDAY";
          break;
        case "Tue":
          newDate.weekName = "TUESDAY";
          break;
        case "Wed":
          newDate.weekName = "WEDNESDAY";
          break;
        case "Thu":
          newDate.weekName = "THURSDAY";
          break;
        case "Fri":
          newDate.weekName = "FRIDAY";
          break;
        case "Sat":
          newDate.weekName = "SATURDAY";
          break;
        case "Sun":
          newDate.weekName = "SUNDAY";
          break;
        default:
          break;
      }
      d.push(newDate);
      setDate(d);
      resolve(newDate);
    });
  };
  // const getDateData = () => {
  //   return new Promise((resolve, reject) => {
  //     let lastDates = [];
  //     let lastTimes = [];
  //     axios
  //       .get(
  //         `https://spreadsheets.google.com/feeds/list/${credentials.spreadSheetId}/od6/public/values?alt=json`
  //       )
  //       .then((res) => {
  //         if (res.data.feed.entry) {
  //           setLoading(true)
  //           const length = res.data.feed.entry.length;
  //           for (var i = length; i >= length - 81; i--) {
  //             if (res.data.feed.entry[i]) {
  //               lastDates = [...lastDates, res.data.feed.entry[i].gsx$date.$t];
  //               lastTimes = [...lastTimes, res.data.feed.entry[i].gsx$time.$t];
  //             }
  //           }
  //           const data = { lastTimes: lastTimes, lastDates: lastDates };
  //           resolve(data);
  //           setLoading(false)
  //         }
  //       });
  //   });
  // };
  // const dateAvailibilty = () => {
  //       dateIn(1)
  //       dateIn(2)
  //       dateIn(3)
  // }
  // const dateIn = (inc) =>{
  //   dateAdder(inc).then((newDate) => {
  //     getDateData().then((response) =>{
  //       for(var i=0;i<response.lastDates.length;i++){
  //         if(newDate.day + "-" + newDate.weekName + "-" + newDate.month === response.lastDates[i]){
  //           switch(inc){
  //             case 1:
  //               get.dayOne = [...get.dayOne,response.lastTimes[i]]
  //               break;
  //             case 2:
  //               get.dayTwo = [...get.dayTwo,response.lastTimes[i]]
  //               break;
  //             case 3:
  //               get.dayThree = [...get.dayThree,response.lastTimes[i]]
  //               break;
  //             default:
  //               return null
  //             }
  //         }
  //       }
  //       dispatch(actions.addLogs(get))
  //     })
  //   })
  // }
  const handleBooking = (event) => {
    event.preventDefault();
    if (!essentials.hasOwnProperty("subject")) {
      setSubjectError(true);
      setDateError(false);
      setTimeError(false);
    } else if (!essentials.hasOwnProperty("date")) {
      setTimeError(false);
      setSubjectError(false);
      setDateError(true);
    } else if (!essentials.hasOwnProperty("time")) {
      setTimeError(true);
      setSubjectError(false);
      setDateError(false);
    } else {
      appendSpreadsheet(essentials);
      appendSpreadsheetDuplicate(essentials);
      setTimeout(() => {
        history.push('/minimal-form/thanks')
      }, 2000);
    }
  };
  return (
   <>
    <Helmet>
      <title>Revamp24 | Schedule a Trialclass</title>
    </Helmet>
    <div className="schedule-container">
      {loading && <Loader />}
      <div className="schedule-sub-container">
        <Link to="#" className="logo-schedule">
          <img src="/logo/logo.png" alt="" />
          {window.innerWidth > 550 && <p>"No student is left behind"</p>}
        </Link>
        <div className="circle-top">
          <h1>Schedule your FREE Class</h1>
          <p>HURRY ! Only 16 slots left</p>
        </div>
        <div className="select_subject_container">
          <h6>
            Select Subject *{" "}
            {subjectError && <span> This field is madidatory</span>}
          </h6>
          <div className="select_subject">
            <div className="sub_set1">
              <SelectSubjectButton
                subject="MATHS"
                subjectImage="/icons/MATHSBLACK.png"
                subjectImageWhite="/icons/MATHSWHITE.png"
                _id="m"
              />
              <SelectSubjectButton
                subject="PHYSICS"
                subjectImage="/icons/PHYSICS-BLACK.png"
                subjectImageWhite="/icons/PHYSICS-WHITE.png"
                _id="p"
              />
            </div>
            <div className="sub_set2">
              <SelectSubjectButton
                subject="CHEMISTRY"
                subjectImage="/icons/CHEMISTRY-BLACK.png"
                subjectImageWhite="/icons/CHEMISTRY-WHITE.png"
                _id="c"
              />
              <SelectSubjectButton
                subject="BIOLOGY"
                subjectImage="/icons/BIOLOGY-BLACK.png"
                subjectImageWhite="/icons/BIOLOGY-WHITE.png"
                _id="b"
              />
            </div>
          </div>
        </div>

        <div className="select-date-container">
          <h6>
            Select a Date *{" "}
            {dateError && <span> This field is madidatory</span>}
          </h6>
          <div className="date-btns">
            {date[2] ? (
              <>
                <SelectDateButton
                  day={date[0].day}
                  week={date[0].weekName}
                  month={date[0].month}
                  _id={date[0].id}
                  dayNum = "dayOne"
                />
                <SelectDateButton
                  day={date[1].day}
                  week={date[1].weekName}
                  month={date[1].month}
                  _id={date[1].id}
                  dayNum = "dayTwo"
                />
                <SelectDateButton
                  day={date[2].day}
                  week={date[2].weekName}
                  month={date[2].month}
                  _id={date[2].id}
                  dayNum = "dayThree"
                />
              </>
            ) : null}
          </div>
        </div>
        <div className="time_container">
          <h6>
            Select a Time(IST) Slot *
            {timeError && <span> This field is madidatory</span>}
          </h6>
          <div className="set1">
            <SelectTime
              disable={isTwelve ? "enable" : "disable"}
              time="12 :00 PM"
              _id="twelve"
            />
            <SelectTime
              disable={isOne ? "enable" : "disable"}
              time="01 :00 PM"
              _id="one"
            />
            <SelectTime
              disable={isTwo ? "enable" : "disable"}
              time="02 :00 PM"
              _id="two"
            />
          </div>
          <div className="set2">
            <SelectTime
              disable={isThree ? "enable" : "disable"}
              time="03 :00 PM"
              _id="three"
            />
            <SelectTime
              disable={isFour ? "enable" : "disable"}
              time="04 :00 PM"
              _id="four"
            />
            <SelectTime
              disable={isFive ? "enable" : "disable"}
              time="05 :00 PM"
              _id="five"
            />
          </div>
          <div className="set3">
            <SelectTime
              disable={isSix ? "enable" : "disable"}
              time="06 :00 PM"
              _id="six"
            />
            <SelectTime
              disable={isSeven ? "enable" : "disable"}
              time="07 :00 PM"
              _id="seven"
            />
            <SelectTime
              disable={isEight ? "enable" : "disable"}
              time="08 :00 PM"
              _id="eight"
            />
          </div>
          <div className="free_button_container">
            <button id="PPC_Schedule_Button_minimal_form" className="free_but" onClick={handleBooking}>
              BOOK A FREE CLASS
            </button>
          </div>
        </div>

        <div className="work_princ_container">
          <h3 className="how">How it Works</h3>
          <div className="align">
            <div className="line"></div>
            <WorkPrinc
              princimg="/icons/mail.svg"
              princword="After booking the class you will receive the class link by SMS/WhatsApp"
              color="mailcolor"
            />

            <WorkPrinc
              princimg="/icons/live.svg"
              princword="Trial class will be Live one-one for 60 minutes"
              color="livecolor"
            />
            <WorkPrinc
              princimg="/icons/online-class.svg"
              princword="Join the class on time and learn from our top teachers"
              color="classcolor"
            />
            <div className="girl_name">
              <WorkPrinc
                princimg="/icons/question.png"
                princword="Get all your doubts clarified instantly during the class"
                color="questioncolor"
              />
            </div>
            <WorkPrinc
              princimg="/icons/homework.png"
              princword="Revise your concepts after class through the homework that will be provided"
              color="homeworkcolor"
            />
            <div className="teacher">
              <WorkPrinc
                princimg="/icons/teacher.svg"
                princword="Get proficiency report discussed with parent"
                color="teachercolor"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
   </>
  );
};
export default SchedulePage;
