
const storeReducer = (state={essential:{haveLaptop:"yes",reload:false}},action) =>{
    switch (action.type) {
        case 'STORE_DATA':
            state = {...state,essential:action.payload}
            return state;
        case 'STORE_DATE':
            state.essential = {...state.essential,date:action.payload}
            return state;
        case 'STORE_SUBJECT':
            state.essential = {...state.essential,subject:action.payload }
            return state;
        case "STORE_TIME":
            state.essential = {...state.essential,time:action.payload}
            return state;
        case "DATE_CHECK":
            state.essential = {...state.essential,dateCheck:action.payload}
            return state;
        case "ADD_LOGS":
            state.essential = {...state.essential,logs : action.payload}
            return state;
        case "TIME_AVAILIBILITY":
            state.essential = {...state.essential,timeAvailibility:action.payload}
            return state
        case "WINDOW_RELOAD":
            state.essential = {...state.essential,reload:true}
            return state
        case "DELETE_SUBJECT":
            delete state.essential.subject;
            return state;
        case "DELETE_DATE":
            delete state.essential.date
            return state;
        case "DELETE_TIME":
            delete state.essential.time
            return state;
        default:
            return state;
    }
}

export default storeReducer;