import React from 'react'
import './ParentCard.scss';

export default function ParentCard({color,parentProfile,parentName,childName,childGrade,cardDescription}) {
    return (
        <div className={"parent-card "+color}>
            <div className="profile-section">
                <div className={"avatar "+color} style={{backgroundImage:`url(/images/${parentProfile})`}}>

                </div>
                <div className="name-section">
                    <h6>{parentName}</h6>
                    <p>{childName}'s parent,Grade {childGrade}</p>
                </div>
            </div>
            <p className="parent-say">"{cardDescription}"</p>
        </div>
    )
}

