import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import './BookingForm.scss';
import { GoChevronDown } from 'react-icons/go'
import { useDispatch } from 'react-redux';
import actions from '../../../../../Assets/dataStore/dataStoreAction';
import credentials from '../../../../../Assets/config';
import { GoogleSpreadsheet } from 'google-spreadsheet'


const BookingFormnew = () => {
    const [formData, setFormData] = useState(null)
    const [mobileVerified, setMobileVerified] = useState(true)
    const [countryActive,setCountryActive] = useState(false)
    const [isGradeSelected, setIsGradeSelected] = useState(false)
    const history = useHistory()

    const dispatch = useDispatch();
    const SPREADSHEET_ID = credentials.spreadSheetIdDemo;
    const SHEET_ID = credentials.sheetId;
    const CLIENT_EMAIL = credentials.client_email;
    const PRIVATE_KEY = credentials.private_key;

    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            await doc.loadInfo();
            const sheet = doc.sheetsById[SHEET_ID];
            const result = await sheet.addRow(row);
            return (result)
        } catch (e) {
            console.error('Error: ', e);
        }
    };
    const handleInput = (event) => {
        const input = event.target.value
        if (input !== "") {
            setFormData({ ...formData, [event.target.name]: input[0].toUpperCase() + input.slice(1) })
        }
        if (event.target.id === "parent-phone") {
            var phoneno = /^\d*$/;
            if (event.target.value.match(phoneno)) {
                setMobileVerified(true)
            } else {
                setMobileVerified(false)
            }
        }
    }
    const handleMobile = (event) =>{
        const countryCode = document.querySelector(".cc").innerHTML
        const mobile = countryCode+event.target.value

            var phoneno = /^\d*$/;
            if (event.target.value.match(phoneno)) {
               
                setMobileVerified(true)
            } else {
                setMobileVerified(false)
                
            }
    
        setFormData({...formData,parentPhone:mobile})
    }

    const gradeHandler = (event) =>{
        var isSelected = document.querySelector('.grade-selected')
        if(isSelected){
            isSelected.classList.remove('grade-selected')
        }
        setIsGradeSelected(false)
        event.target.classList.toggle('grade-selected')
        setFormData({...formData,grade:event.target.innerHTML})
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(formData.hasOwnProperty('grade')){
            if (!formData.hasOwnProperty('haveLaptop')) {
                formData.haveLaptop = "yes"
            }
            dispatch(actions.storeData(formData))
            appendSpreadsheet(formData)
            history.push("/minimal-form/schedule")
        }else{
            setIsGradeSelected(true)
        }
        // var grade = document.getElementById('grade').innerHTML;
        // var board = document.getElementById('board').innerHTML;
        // if (grade === "Class") {
        //     setClassAndBoard(false)
        // } else if (board === "Board") {
        //     setClassAndBoard(false)
        // }
        // else {
        //     setClassAndBoard(true)
        //     if (!formData.hasOwnProperty('haveLaptop')) {
        //         formData.haveLaptop = "yes"
        //     }
        //     dispatch(actions.storeData(formData))
        //     appendSpreadsheet(formData)
        //     history.push("/schedule")
        // }
    }
    const countrySelect = (event) =>{
        document.querySelector('.cc').innerHTML = event.target.innerHTML
        setFormData({...formData,parentPhone:event.target.innerHTML+document.querySelector('#parent-phone').value})
    }
    return (
        <form id="PPC_TrialClass_minimal_form" className="booking-form" onSubmit={handleSubmit}>
            <h2>Book A Free Class for your child</h2>    
            <div className="mobile-field">
                <div className="country-code" onClick={()=>setCountryActive(!countryActive)}>
                    <p className="cc">+971</p>
                    <GoChevronDown />
                    <ul className={countryActive?"countries countries-drop":"countries"}>
                        <li onClick={countrySelect}>+971</li>
                        <li onClick={countrySelect}>+965</li>
                        <li onClick={countrySelect}>+91</li>
                        <li onClick={countrySelect}>+974</li>
                        <li onClick={countrySelect}>+966</li>
                        <li onClick={countrySelect}>+968</li>
                        <li onClick={countrySelect}>+973</li>
                    </ul>
                </div>
                <input type="tel" name="parentPhone" id="parent-phone" required placeholder="Parent Phone" onChange={handleMobile} />
            </div>

            <p className={mobileVerified ? "wrong-mobile inactive" : "wrong-mobile"}>Enter valid Mobile number *</p>
            <input type="text" name="childName" id="child-name" required placeholder="Child Name" onChange={handleInput} />

            <label htmlFor="grade" className="grade-label">Select grade</label>
            <div className="grade-container">
                <ul>
                    <li onClick={gradeHandler}>5</li>
                    <li onClick={gradeHandler}>6</li>
                    <li onClick={gradeHandler}>7</li>
                    <li onClick={gradeHandler}>8</li>
                    <li onClick={gradeHandler}>9</li>
                    <li onClick={gradeHandler}>10</li>
                    <li onClick={gradeHandler}>11</li>
                    <li onClick={gradeHandler}>12</li>
                </ul>
            </div>
            <p className={!isGradeSelected ? "wrong-mobile inactive" : "wrong-mobile"}>Please select a grade *</p>
            <label htmlFor="have-laptop" className="radio-label">Do you have laptop?</label>
            <div className="radios">
                <input type="radio" name="haveLaptop" required value="yes" id="yes" checked onChange={handleInput} />
                <label htmlFor="Yes">Yes</label>
                <input type="radio" name="haveLaptop" required value="no" id="no" onChange={handleInput} />
                <label htmlFor="no">No</label>
            </div>
            <button id="PPC_Trialclass_minmal_form_button" type="submit">BOOK A FREE CLASS</button>
            <Link to="" className="agreement"><span>By registering,I agree to</span> Revamp24's Privacy Policy</Link>
        </form>
    )
}

export default BookingFormnew;
