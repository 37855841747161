import React from 'react'
import { Link } from 'react-router-dom';
import './Header.scss'

const Header = () => {
    return (
        <nav>
            <Link to="/" className="logo-link">
                <img src="/logo/logo.png" alt=""/>
                <p>"No student is left behind"</p>
            </Link>
        </nav>
    )
}

export default Header;
