import React from 'react'
import './MainFooter.scss'

export default function MainFooter() {
    return (
        <div className="footer">
        <h4>© 2021 Revamp24 Pvt. Ltd.</h4>

      </div>
    )
}
