import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { createStore } from 'redux';
import storeReducer from './Assets/dataStore/dataStoreReducer';
import { Provider } from 'react-redux';

const store = createStore(storeReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

ReactDOM.render( 
    <Provider store={store}>
        <App />
    </Provider>
    ,document.getElementById('root'));

