import BookingFormnew from './BookingForm/BookingFormnew'
import './LandingPage.scss'
import MobileSpecial from './MobileSpecial/MobileSpecial'
import SpecialityCards from './Speciality-card/SpecialityCard'

const LandingPagenew = () => {

    return (
        <div className="landing-page-container" style={{ backgroundImage: `url('/bg/Mask Group 7.png')` }}>
            <h1>Best online maths and science personalised learning
                programmes for kids in 8-12 th grade<span className="across-text">,across all syllabus.</span></h1>

            <MobileSpecial text="Individualized attention, LIVE with a teacher.No recorded lectures" />
            <MobileSpecial text="Clear doubts instantly and learn faster" />

            <div className="landing-contents">
                <div className="speciality-cards-container">
                    <h3>How Revamp24 is different?</h3>
                    <SpecialityCards icon="/icons/live.png" speciality="1-1 attention from a live teacher" />
                    <SpecialityCards icon="/icons/doubt2.png" speciality="We love answering doubts. The more you ask the better you get." />
                    <SpecialityCards icon="/icons/syllabus.png" speciality="Global curriculum CBSE ICSE IGCSE, ISC ,state board etc" />
                    <SpecialityCards icon="/icons/analytics.png" speciality="Dedicated mentoring and weekly assessment reports" />
                </div>
                <div className="form-container">
                    <BookingFormnew />
                </div>
            </div>
        </div>
    )
}

export default LandingPagenew;
