import React, { useEffect } from 'react'
import LandingPage from './LandingPage/LandingPage'
import { useSelector } from 'react-redux'
import ParentSection from '../parent-section/ParentSection'



const Home = () => {
    const essentials = useSelector(state => state.essential)

    // const appendSpreadsheet = async (row) => {
    //     try {
    //         await doc.useServiceAccountAuth({
    //             client_email: CLIENT_EMAIL,
    //             private_key: PRIVATE_KEY,
    //         });
    //         await doc.loadInfo();

    //         const sheet = doc.sheetsById[SHEET_ID];
    //         const result = await sheet.addRow(row);
    //         return result;
    //     } catch (e) {
    //         console.error("Error: ", e);
    //     }
    // };

    useEffect(() => {
        // getPreviousData()
        window.scrollTo(0, 0)
        if(essentials.reload){
            window.location.reload(false)
        }
    }, [])
    // const getPreviousData = () => {
    //     axios
    //         .get(`https://spreadsheets.google.com/feeds/list/${credentials.spreadSheetIdDemo}/od6/public/values?alt=json`)
    //         .then((data) => {
    //             axios.get(`https://spreadsheets.google.com/feeds/list/${credentials.spreadSheetId}/od6/public/values?alt=json`)
    //                 .then((res) => {
    //                     if (data.data.feed.entry) {
    //                         const lengthFirstForm = data.data.feed.entry.length - 1
    //                         const lengthSecondForm = res.data.feed.entry.length - 1 
    //                         const number = data.data.feed.entry[lengthFirstForm ].gsx$parentphone.$t
    //                         const numberSecondForm = res.data.feed.entry[lengthSecondForm ].gsx$parentphone.$t
    //                         if (number !== numberSecondForm) {
    //                             const arr = data.data.feed.entry[lengthFirstForm];
    //                             if(arr){
    //                                 const details = {
    //                                     parentName: arr.gsx$parentname.$t,
    //                                     parentPhone: arr.gsx$parentphone.$t,
    //                                     parentEmail: arr.gsx$parentemail.$t,
    //                                     childName: arr.gsx$childname.$t,
    //                                     board: arr.gsx$board.$t,
    //                                     grade: arr.gsx$grade.$t
    //                                 }
    //                                 appendSpreadsheet(details)
    //                             }
    //                         }
    //                     }
    //                 })
    //         })
    // }

    return (

        <div>
            <LandingPage />
            <ParentSection/>
        </div>
    )
}

export default Home;
