import React from 'react'
import ParentCard from '../parentCard/ParentCard'
import './ParentSection.scss'

export default function ParentSection() {
    return (
        <section className="parent-section">
            <h1>See what parents say about us</h1>
            <div className="card-container">
                <ParentCard
                    parentProfile="sudeep.jpeg"
                    parentName="Sudeep Bose"
                    childName="Srijan Bose"
                    childGrade="12"
                    cardDescription="We find one on one interation with student effective. Student has shown progress and developed interest in physics which was not the case in grade XI."
                />
                <ParentCard 
                    color="purple"
                    parentProfile="vrindha.png"
                    parentName="Vrindha"
                    childName="Ananya"
                    childGrade="11"
                    cardDescription="My 11th grader is really happy with Revamp classes because she got a connect with the teacher as well as the subject. The teachers have been making sincere efforts to give good foundation and to make her practice. Thanks to Revamp24."
                />
                <ParentCard 
                    color="blue"
                    parentProfile="madhavan.jpeg"
                    parentName="Madhavan SR"
                    childName="Advaith"
                    childGrade="12"
                    cardDescription="Revamp has brought in the type of professional coaching that’s required in this age of technology.Good research on course material for school and competitive exams were also shared proactively."
                />
            </div>
        </section>
    )
}
