import {BrowserRouter as Router, Switch,Route} from 'react-router-dom';
import Header from './Components/minimal form/Header/Header';
import Homenew from './Components/minimal form/Home/Homenew';
import Home from './Components/minimal form/Home/Home';
import SchedulePage from './Components/minimal form/SchedulePage/SchedulePage';
import MainFooter from './Components/minimal form/MainFooter/MainFooter'
import ThanksPage from './Components/minimal form/ThanksPage/ThanksPage';
import HomeMain from './Components/ppc/Home/Home'
import ScheduleMain from './Components/ppc/SchedulePage/SchedulePage'
import ThanksPageMain from './Components/ppc/ThanksPage/ThanksPage'

function App() {

  return (
     <Router>
       <Switch>
         <Route path="/" exact>
          <Header/>
          <HomeMain/>
         </Route>
         <Route path="/schedule">
          <ScheduleMain/>
         </Route>
         <Route path="/thanks">
            <ThanksPageMain/>
         </Route>
         <Route path="/minimal-form" exact>
            <Header/>
            <Home/>
         </Route>
         <Route path="/minimal-form-new" exact>
            <Header/>
            <Homenew/>
         </Route>
         <Route path="/minimal-form-new/schedule">
           <SchedulePage/>
         </Route>
         <Route path="/minimal-form/schedule">
           <SchedulePage/>
         </Route>
         <Route path="/minimal-form-new/thanks">
           <ThanksPage/>
         </Route>
         <Route path="/minimal-form/thanks">
           <ThanksPage/>
         </Route>
       </Switch>
      <MainFooter/>
     </Router>
  );
}

export default App;
